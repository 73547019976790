<template>
  <div class="cases" @click="handleViewCase">
    <div class="case-item">
      <img :src="img" />
      <div class="case-foot">
        <div style="flex:1">
          <div class="case-title">{{ caseInfo.title }}</div>
          <div class="other-info">
            {{ caseInfo.typeName }} | {{ caseInfo.area }}m² |
            {{ caseInfo.casePosition }}
          </div>
        </div>
        <div
          class="transh-box"
          v-if="showDel"
          @click.stop="emitDelete(caseInfo.caseId)"
        >
          <icon-svg class="delete-icon" iconClass="icontrash-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notfound from "@/assets/images/case-defaultimg.png";
import trashPng from "@/assets/icons/trash-2.png";
import { computed } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    showDel: {
      type: Boolean,
      default: false,
    },
    caseInfo: {
      type: Object,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const emitDelete = (caseId) => {
      emit("deletCase", caseId);
    };
    const handleViewCase = () => {
      if (!props.isShared) {
        router.push({
          path: "/caseVisitors/caseDetails",
          query: { caseId: props.caseInfo.caseId },
        });
      } else {
        emit("viewCase");
      }
    };
    let img = computed(() => {
      if (props.caseInfo.headImgUrl) {
        return `${process.env.VUE_APP_WATCH_FILE_API}/${props.caseInfo.headImgUrl}`;
      } else {
        return notfound;
      }
    });
    return { img, trashPng, emitDelete, handleViewCase };
  },
};
</script>

<style lang="scss" scoped>
.cases {
  padding:8px 16px;
  border-bottom: 1px solid #f1f3f6;
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
  }
  .case-item {
    .case-foot {
      display: flex;
      flex-direction: row;
      .transh-box {
        align-self: flex-end;
        .delete-icon {
          padding: 20ppx;
          width: 18px;
          height: 18px;
        }
      }
    }
    img {
      width: 100%;
      height: 160px;
      object-fit:cover;
    }
    .case-title {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
    .other-info {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>
