<template>
  <div class="show-user-card">
    <van-sticky>
      <van-nav-bar left-arrow safe-area-inset-top @click-left="handleReturn">
        <template #left>
          <van-icon name="arrow-left" color="#161719" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="user-info">
      <div class="company">{{ cardInfo.merchantName }}</div>
      <img class="head-img" :src="headImg" alt="" />
      <div class="name">{{ cardInfo.name }}</div>
      <div class="phone">{{ cardInfo.contactNum }}</div>
      <div class="role">{{ cardInfo.roleName }}</div>
      <div class="desc">
        {{ cardInfo.selfIntro }}
      </div>
    </div>
    <div class="case-box">
      <case-item :caseInfo="showCaseItem" />
    </div>

    <img class="qr-code" :src="cardInfo.qrCode" alt />
  </div>
</template>

<script>
import { getUserCard } from "@/api/user/userCard";
import caseItem from "@/components/caseItem.vue";
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import notfound from "@/assets/head-img-not-found.png";
import { useRouter } from "vue-router";
export default {
  components: {
    caseItem,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      cardInfo: {
        cardId: "",
        contactNum: "",
        name: "",
        wxId: "",
        selfIntro: "",
        impressionLabel: "",
        officialWebsite: "",
        qrCode: "",
        myCaseList: [],
      },
    });
    let headImg = computed(() => {
      if (state.cardInfo.headImgUrl) {
        return `${process.env.VUE_APP_WATCH_FILE_API}/${state.cardInfo.headImgUrl}`;
      } else {
        return notfound;
      }
    });
    let showCaseItem = computed(() => {
      if (state.cardInfo.myCaseList.length) {
        return state.cardInfo.myCaseList[0];
      } else {
        return [];
      }
    });
    const loadUserCardInfo = async () => {
      store.commit("showLoading");
      state.cardInfo = await getUserCard();
      console.log(state.cardInfo);
      store.commit("hideLoading");
    };
    const handleReturn = () => {
      router.back();
    };
    loadUserCardInfo();
    return {
      ...toRefs(state),
      headImg,
      showCaseItem,
      handleReturn,
    };
  },
};
</script>

<style lang="scss" scoped>
.show-user-card {
  .case-box {
    text-align: left;
  }
  .company {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 16px;
  }
  .head-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    padding: 4px 16px;
  }
  .phone {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    padding: 4px 16px;
  }
  .role {
    font-size: 14px;
    color: #333333;
    padding: 4px 16px;
  }
  .desc {
    font-size: 14px;
    color: #333333;
    padding: 4px 16px;
  }
  .qr-code {
    margin-top: 20px;
    width: 150px;
    height: 150px;
  }
}
</style>
